@import "src/styles/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    flex-direction: column;
    height: 100%;
}

.fields {
    width: 400px;
    border: 1px solid var(--grey-color);
    padding: 30px 50px;
    border-radius: 10px;
    text-align: center;
}

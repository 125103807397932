.timeFields {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.timeField {
  margin-right: 10px;
}

.allFieldsContainer {
  margin-bottom: 18px;
}

.allFields {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

.container {
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1640px;

  @media (max-width: 1440px) {
    padding: 0 16px;
  }

  &_thin {
    margin: 0 auto;
    width: 660px;
  }
}

.fields-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  margin-bottom: 16px;

  span {
    word-break: break-word;
  }
}

.filterContainer {
  display: flex;
  align-items: center;
}

.filterTitle {
  margin-right: 8px
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 24.5%);
  gap: 10px;
  margin: 16px 0;
}

.card {
  border: 1px solid #8e9698;
}

.cardTitle {
  margin-bottom: 32px;
}

.cardPercentText {
  color: green;
  font-weight: 500;
}

.cardStatistic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: fixed;
  z-index: 1000000;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
}

.alert {
  width: 400px;
}

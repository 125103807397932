@import "src/styles";

.button {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.link {
  &_active {
    text-decoration: underline;
    font-weight: bold;
  }
}

.notificationChip {
  margin-left: 6px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
}

.text {
  color: #8e9698;
}

.form {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  grid-column-gap: 12px;
  grid-row-gap: 6px;
}

.formLabel {
  padding-top: 12px;
}

@import "adaptive";

:root {
    /* Colors */

    --black-color: #231f20;
    --white-color: #ffffff;
    --red-color: #b90f36;
    --grey-color: #8e9698;
    --dark-yellow-color: #ab7c12;
    --dark-grey-color: #2a373d;
    --dark-blue-color: #012e55;

    --error: var(--red-color);

    --t: transparent;
}

@import 'src/styles/variables';
@import 'src/styles/adaptive';

.header {
  background-color: var(--dark-blue-color);
  padding: 20px 0;
  color: var(--white-color);
  margin-bottom: 32px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.name {
  width: 100px;
  display: block;

  @include respond-down(l) {
    display: none;
  }
}

.navigation {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user {
  display: flex;
  align-items: center;

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__role {
    font-size: 12px;
    display: inline-block;
    margin-top: 4px;
    text-decoration: underline;
  }
}

.container {
  margin: 0 auto 32px;
  width: 800px;
}

.fields {
  display: grid;
  padding: 16px;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.filterTitle {
  margin-right: 8px
}

.container {
  margin-bottom: 24px;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  gap: 10px;
  margin-top: 16px;
}

.card {
  border: 1px solid #8e9698;
}

.cardTitle {
  margin-bottom: 32px;
  font-weight: 500;
}

.cardPercentText {
  color: green;
  font-weight: 500;
}

.cardStatistic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.usersRow {
  border-bottom: 1px solid #8e9698;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
}

@import "../../styles/_variables.scss";

.uikit {
    margin: 20px;

    &__color {
        margin: 10px;
        padding: 20px;
        display: inline-block;

        &_black {
            background-color: var(--black-color);
            color: var(--white-color);
        }

        &_white {
            background-color: var(--white-color);
            border: 1px solid var(--black-color);
        }

        &_red {
            background-color: var(--red-color);
        }

        &_yellow {
            background-color: var(--dark-yellow-color);
        }

        &_grey {
            background-color: var(--grey-color);
        }

        &_dark-grey {
            background-color: var(--dark-grey-color);
        }

        &_blue {
            background-color: var(--dark-blue-color);
        }
    }
}
